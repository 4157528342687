<template>
    <div class="timeline-container">
        <div class="timeline">
            <div class="timeline-container approved">
                <div class="timeline-icon">
                    <b-icon-check scale="1.5" />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title">Inicio de la solicitud</h4>
                    <p class="timeline-subtitle">
                        {{ startDate }}
                    </p>
                </div>
            </div>
            <div class="timeline-container" :class="firstValidation.status">
                <div class="timeline-icon">
                    <b-icon
                        v-if="firstValidation.icon !== null"
                        :icon="firstValidation.icon"
                        scale="1.5"
                    />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title">
                        Primera validación de Superintendencia
                    </h4>
                    <p>
                        {{ firstValidation.userName }}
                    </p>
                    <p class="timeline-subtitle">
                        {{ firstValidation.date }}
                    </p>
                </div>
            </div>
            <div class="timeline-container" :class="secondValidation.status">
                <div class="timeline-icon">
                    <b-icon
                        v-if="secondValidation.icon !== null"
                        :icon="secondValidation.icon"
                        scale="1.5"
                    />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title">
                        Segunda validación de Superintendencia
                    </h4>
                    <p v-if="secondValidation.status !== 'pending'">
                        {{ secondValidation.userName }}
                    </p>
                    <p
                        v-if="secondValidation.status !== 'pending'"
                        class="timeline-subtitle"
                    >
                        {{ secondValidation.date }}
                    </p>
                </div>
            </div>
            <div
                class="timeline-container"
                :class="pendingCompetentAuthority.status"
            >
                <div class="timeline-icon">
                    <b-icon
                        v-if="pendingCompetentAuthority.icon !== null"
                        :icon="pendingCompetentAuthority.icon"
                        scale="1.5"
                    />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title">
                        Pendiente de lectura por Autoridad Competente
                    </h4>
                    <p v-if="pendingCompetentAuthority.status !== 'pending'">
                        {{ pendingCompetentAuthority.userName }}
                    </p>
                    <p
                        v-if="pendingCompetentAuthority.status !== 'pending'"
                        class="timeline-subtitle"
                    >
                        {{ pendingCompetentAuthority.date }}
                    </p>
                </div>
            </div>
            <div class="timeline-container" :class="onReading.status">
                <div class="timeline-icon">
                    <b-icon
                        v-if="onReading.icon !== null"
                        :icon="onReading.icon"
                        scale="1.5"
                    />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title">
                        En lectura por Autoridad Competente
                    </h4>
                </div>
            </div>
            <div
                class="timeline-container"
                :class="[isCompleted ? 'approved' : 'pending']"
            >
                <div class="timeline-icon">
                    <b-icon-flag scale="1" />
                </div>
                <div class="timeline-body">
                    <h4 class="timeline-title text-center">Completado</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "procedure-timeline",
    props: {
        procedure: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            timeline: null,
            startDate: null,
            firstValidation: {},
            secondValidation: {},
            pendingCompetentAuthority: {},
            onReading: {},
        };
    },
    mounted() {
        this.parseTimeline();
    },
    computed: {
        isCompleted() {
            return this.procedure.status === 4;
        },
    },
    methods: {
        parseTimeline() {
            this.timeline = this.procedure.timeline;

            this.startDate = this.parseAndConvertDate(this.timeline.startDate);

            this.parseFirtsValidation();
            this.parseSecondValidation();
            this.parsePendingCompetentAuhtority();
            this.parseOnReading();
        },
        parseFirtsValidation() {
            let timeline = this.timeline;
            let status = this.getValidationStatus(
                timeline.firstValidationDate,
                timeline.startDate
            );
            let firstValidation = {
                status: status,
                icon: this.getIcon(status),
            };

            if (status === "rejected") {
                let rejected = this.procedure.reject;

                firstValidation.date = this.parseAndConvertDate(
                    rejected.cancellationDate
                );

                firstValidation.userName =
                    rejected.cancellationSuperintendence.name;
            } else {
                firstValidation.date =
                    timeline.firstValidationDate !== null
                        ? this.parseAndConvertDate(timeline.firstValidationDate)
                        : "-";
                firstValidation.userName =
                    timeline.firstValidationSuperintendence !== null
                        ? timeline.firstValidationSuperintendence.name
                        : "-";
            }

            this.firstValidation = firstValidation;
        },
        parseSecondValidation() {
            let timeline = this.timeline;
            let status = this.getValidationStatus(
                timeline.secondValidationDate,
                timeline.firstValidationDate
            );
            let secondValidation = {
                status: status,
                icon: this.getIcon(status),
            };

            if (status === "rejected") {
                let rejected = this.procedure.reject;

                secondValidation.date = this.parseAndConvertDate(
                    rejected.cancellationDate
                );

                secondValidation.userName =
                    rejected.cancellationSuperintendence.name;
            } else {
                secondValidation.date =
                    timeline.secondValidationDate !== null
                        ? this.parseAndConvertDate(
                              timeline.secondValidationDate
                          )
                        : "-";
                secondValidation.userName =
                    timeline.secondValidationSuperintendence !== null
                        ? timeline.secondValidationSuperintendence.name
                        : "-";
            }

            this.secondValidation = secondValidation;
        },
        parsePendingCompetentAuhtority() {
            let timeline = this.timeline;
            let status =
                this.procedure.status === 5
                    ? "pending"
                    : this.procedure.status === 2
                    ? "actual"
                    : this.procedure.status < 2
                    ? "pending"
                    : "approved";
            this.pendingCompetentAuthority = {
                date:
                    timeline.firstReadDate !== null
                        ? this.parseAndConvertDate(timeline.firstReadDate)
                        : "-",
                status: status,
                icon: this.getIcon(status),
                userName:
                    timeline.firstReadDate !== null
                        ? timeline.firstReadOfficial.name
                        : "-",
            };
        },
        parseOnReading() {
            let state = this.procedure.status;
            let status =
                state === 5
                    ? "pending"
                    : state === 3
                    ? "actual"
                    : state < 3
                    ? "pending"
                    : "approved";
            this.onReading = {
                status: status,
                icon: this.getIcon(status),
            };
        },
        getValidationStatus(date, previousDate) {
            if (date === null) {
                if (previousDate !== null) {
                    if (this.procedure.status === 5) {
                        return "rejected";
                    }
                    return "actual";
                }
                return "pending";
            }
            return "approved";
        },
        getIcon(status) {
            switch (status) {
                case "approved":
                    return "check";
                case "actual":
                    return "arrow-right";
                case "rejected":
                    return "x";
            }

            return null;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
    },
};
</script>

<style lang="scss" scoped>
// colors
$actual: orange;
$approved: #00bf9a;
$rejected: #e21a1a;

// timeline variables
$timeline-before-bg: #222a42;
$timeline-body-bg: #27293d;
$timeline-item-inactive: gray;
$timeline-body-round: 3px;
$timeline-body-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

body {
    font-family: "K2D", sans-serif;
    letter-spacing: 1px;
}

// page container
.timeline-container {
    max-width: 800px;
    margin: 30px auto;
}

// typography
p {
    font-weight: 300;
    line-height: 1.5;
    font-size: 14px;
    opacity: 0.8;
}

// timeline style
.timeline {
    position: relative;
    padding-left: 4rem;
    margin: 0 0 0 30px;
    color: white;

    &:before {
        content: "";
        position: absolute;
        left: -1px;
        top: 1rem;
        width: 4px;
        height: calc(100% - 2.5rem);
        background: $timeline-body-bg;
    }

    .timeline-container {
        position: relative;
        margin-bottom: 2.5rem;

        .timeline-icon {
            position: absolute;
            left: -88px;
            top: 4px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            font-size: 2rem;

            i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .timeline-body {
            background: $timeline-body-bg;
            border-radius: $timeline-body-round;
            padding: 20px 20px 15px;
            box-shadow: $timeline-body-shadow;

            &:before {
                content: "";
                background: inherit;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                left: -10px;
                transform: rotate(45deg);
                border-radius: 0 0 0 2px;
            }

            .timeline-title {
                margin-bottom: 0.5rem;

                .badge {
                    padding: 4px 8px;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            .timeline-subtitle {
                font-weight: 300;
                font-style: italic;
                opacity: 0.4;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                font-size: 11px;
            }
        }

        // Actual Step Timeline
        &.actual {
            .badge,
            .timeline-icon {
                background: $actual !important;
            }
        }

        // Approved Timeline
        &.approved {
            .badge,
            .timeline-icon {
                background: $approved !important;
            }
        }

        // Dnager Timeline
        &.rejected {
            .badge,
            .timeline-icon {
                background: $rejected !important;
            }
        }

        &.pending {
            .badge,
            .timeline-icon {
                background: $timeline-item-inactive !important;
            }

            .timeline-body {
                opacity: 0.5;
            }
        }
    }
}

.author {
    font-family: inherit;
    padding: 3em;
    text-align: center;
    width: 100%;
    color: white;

    a:link,
    a:visited {
        color: white;
        &:hover {
            text-decoration: none;
        }
    }
    .btn:link,
    .btn:visited {
        margin-top: 1em;
        text-decoration: none;
        display: inline-block;
        font-family: inherit;
        font-weight: 100;
        color: white;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: black;
        padding: 1.5em 2rem;
        border-radius: 1em;
        transition: 0.5s all;
        &:hover,
        &:focus,
        &:active {
            background-color: lighten(black, 10%);
        }
    }
}
</style>