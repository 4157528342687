<template>
    <div class="text-start my-2">
        <h6 class="page-title mt-3">Información del proceso</h6>
        <ProcedureInfoProperty
            label="Autoridad Competente"
            :text="procedure.official.competentAuthority.name"
        />

        <ProcedureInfoProperty
            label="Solicitante"
            :text="procedure.official.name"
        />

        <ProcedureInfoProperty
            label="Fecha de solicitud"
            :text="procedure.startDate"
        />

        <ProcedureInfoProperty label="Estado" :text="status" />

        <ProcedureInfoProperty
            label="Descripción"
            :text="procedure.description"
        />

        <h6 class="page-title mt-3">Folios</h6>
        <b-row align-h="start">
            <b-col
                sm="4"
                lg="3"
                v-for="(response, f) in procedure.responses"
                :key="f"
            >
                <div class="folio">
                    {{ getSocietyType(response.societyType) }} {{ response.folio }}
                </div>
            </b-col>
        </b-row>

        <ProcedureReject
            v-if="procedure.reject !== null"
            :reject="procedure.reject"
        />
    </div>
</template>

<script>
import { getFinalBeneficiaryStatus } from "@/config/procedures";

import ProcedureInfoProperty from "./ProcedureInfoProperty.vue";
import ProcedureReject from "./ProcedureReject.vue";
import { societyType } from "@/config/society-type";

export default {
    name: "procedure-info",
    components: {
        ProcedureInfoProperty,
        ProcedureReject,
    },
    props: {
        procedure: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status() {
            return getFinalBeneficiaryStatus(this.procedure.status);
        },
    },
    data() {
        return {
            societyType: societyType
        }
    },
    methods: {
        getSocietyType(value) {
            return this.societyType.find(x => x.value === value).text;
        }
    }
};
</script>

<style lang="scss" scoped>
.folio {
    margin-top: 0.5rem;
    border: 1px solid #b3b3b3;
    background-color: #ececec;
    border-radius: 0.25rem;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
</style>