import { render, staticRenderFns } from "./RejectModal.vue?vue&type=template&id=a0d0893a&scoped=true"
import script from "./RejectModal.vue?vue&type=script&lang=js"
export * from "./RejectModal.vue?vue&type=script&lang=js"
import style0 from "./RejectModal.vue?vue&type=style&index=0&id=a0d0893a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0d0893a",
  null
  
)

export default component.exports