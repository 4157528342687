<template>
    <b-row class="mb-2">
        <b-col sm="3">
            <label class="details-title">
                {{ label }}
            </label>
        </b-col>
        <b-col>
            <span class="details-title-value">
                {{ text }}
            </span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "procedure-info-property",
    props: {
        label: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
};
</script>