<template>
    <div>
        <h5 class="text-end page-title">
            {{ countdown }}
        </h5>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "procedure-timer",
    props: {
        procedure: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            totalSeconds: 45 * 60,
            countdown: "--:--",

            interval: null,
            timeout: null,
        };
    },
    mounted() {
        let status = this.procedure.status;
        let firstReadDate = this.procedure.timeline.firstReadDate;

        if (status === 3 && firstReadDate !== null) {
            let start = moment.utc(firstReadDate);
            this.startTimer(start);
        }
    },
    beforeDestroy() {
        this.stopTimer();
        this.stopTimeout();
    },
    methods: {
        startTimer(start) {
            this.interval = setInterval(() => {
                this.computeTimer(start);
            }, 1000);
        },
        computeTimer(start) {
            let now = moment.utc();
            let diference = moment.duration(now.diff(start)).asSeconds();
            diference = this.totalSeconds - diference;
            if (diference <= 0) {
                this.stopTimer();
                this.countdown =
                    "PROCESO EXPIRADO SE LE VA A RETIRAR EL ACCESO EN BREVES MOMENTOS";
                this.startTimeout();
                return;
            }

            let minutes = Math.floor(diference / 60)
                .toString()
                .padStart(2, "0");
            let seconds = Math.floor(diference % 60)
                .toString()
                .padStart(2, "0");
            this.countdown = `${minutes}:${seconds}`;
        },
        stopTimer() {
            if (this.interval !== null) {
                clearInterval(this.interval);
            }
        },
        startTimeout() {
            this.timeout = setTimeout(() => {
                location.reload();
            }, 30 * 1000);
        },
        stopTimeout() {
            if (this.timeout !== null) {
                clearTimeout(this.timeout);
            }
        },
    },
};
</script>