<template>
    <b-table
        small
        stacked="lg"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="No hay beneficiarios finales que mostrar"
        responsive
    >
        <template #cell(birthDate)="data">
            {{ parseDate(data.item.birthDate) }}
        </template>
        <template #cell(statusAcquireDate)="data">
            {{ parseDate(data.item.statusAcquireDate) }}
        </template>
        <template #cell(reason)="data">
            <b-icon-card-list
                style="cursor: pointer"
                v-b-tooltip.hover.left
                variant="primary"
                :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
            />
        </template>
        <template #cell(creationDate)="data">
            {{ parseAndConvertDate(data.item.creationDate) }}
        </template>
    </b-table>
</template>

<script>
import moment from "moment";
import { reasons } from "@/config/final-beneficiary-reasons";

export default {
    name: "complete-final-beneficiaries",
    props: {
        finalBeneficiaries: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                { key: "name", label: "Primer Nombre" },
                { key: "secondName", label: "Segundo Nombre" },
                { key: "surnames", label: "Primer Apellido" },
                { key: "secondSurname", label: "Segundo Apellido" },
                { key: "cedula", label: "Cédula" },
                { key: "birthDate", label: "Fecha de nacimiento" },
                { key: "nationality", label: "Nacionalidad" },
                { key: "direction", label: "Dirección" },
                { key: "statusAcquireDate", label: "Fecha de adquisición" },
                { key: "email", label: "Correo electrónico" },
                { key: "phone", label: "Teléfono" },
                { key: "reason", label: "Motivo", class: "text-center" },
                { key: "creationDate", label: "Fecha de creación" },
            ],
            items: [],
        };
    },
    mounted() {
        this.items = this.finalBeneficiaries;
    },
    methods: {
        parseAndConvertDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        parseDate(date) {
            if (date === null) {
                return "-";
            }
            return moment(date).format("DD/MM/YYYY");
        },
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        },
    },
};
</script>