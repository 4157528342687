<template>
    <b-row class="mt-2">
        <h6 class="page-title mt-3">Archivos adjuntados</h6>
        <b-col
            v-for="attachment in attachments"
            :key="attachment.id"
            xl="4"
            md="6"
        >
            <b-card no-header no-body class="shadow-sm rounded-0">
                <div
                    class="mb-2 py-1 cs-pointer text-center"
                    @click="downloadAttachment(attachment)"
                >
                    <label class="cs-pointer">
                        {{ attachment.name }}
                    </label>
                    <b-spinner
                        v-if="isDownloading(attachment)"
                        class="ms-2"
                        variant="primary"
                        small
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
const apiUrl = process.env.VUE_APP_BACKEND_API_URL;

export default {
    name: "procedure-attachments",
    props: {
        attachments: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            downloadingId: null,
        };
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcAccessToken"]),
    },
    methods: {
        isDownloading(attachment) {
            return this.downloadingId === attachment.id;
        },
        async downloadAttachment(attachment) {
            this.downloadingId = attachment.id;
            try {
                let response = await axios({
                    url: `${apiUrl}api/procedure/final-beneficiary/attachment/${attachment.id}`,
                    method: "GET",
                    responseType: "blob",
                    headers: {
                        authorization: `Bearer ${this.oidcAccessToken}`,
                    },
                });

                this.downloadFile(response.data, attachment.name);
            } catch (error) {
                window.console.log(error);
            }
            this.downloadingId = null;
        },
        downloadFile(data, filename) {
            var fileURL = window.URL.createObjectURL(new Blob([data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);

            fileLink.click();
        },
    },
};
</script>