function getFinalBeneficiaryStatus(status) {
    switch (status) {
        case 0:
            return "Primera validación de Superintendencia";
        case 1:
            return "Segunda validación de Superintendencia";
        case 2:
            return "Pendiente de lectura por Autoridad Competente";
        case 3:
            return "En lectura";
        case 4:
            return "Completado";
        case 5:
            return "Cancelado";
    }

    return "-";
}

export {
    getFinalBeneficiaryStatus
}