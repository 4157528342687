<template>
    <b-tabs>
        <b-tab
            v-for="(legalEntity, l) in legalEntities"
            :key="l"
            :title="`${getSocietyType(legalEntity.societyType)} ${legalEntity.folio}`"
            class="p-3"
        >
            <div v-if="legalEntity.legalEntityId !== null">
                <LegalEntity
                    :id="legalEntity.legalEntityId"
                    :procedureId="procedureId"
                />
                <FinalBeneficiaries
                    :finalBeneficiaries="legalEntity.finalBeneficiaries"
                />
            </div>
            <div class="text-center mt-3" v-else>
                <h6>Actualmente, el folio <strong>'{{ getSocietyType(legalEntity.societyType) }} {{legalEntity.folio }}'</strong> no se encuentra activo en el sistema.</h6>
                <p>Puede verificar la sección de <strong>Historial de las Sociedades</strong> para observar los registros realizados anteriormente.</p>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import LegalEntity from "./LegalEntity.vue";
import FinalBeneficiaries from "./final-beneficiaries/FinalBeneficiaries.vue";
import { societyType } from "@/config/society-type";

export default {
    components: {
        LegalEntity,
        FinalBeneficiaries,
    },
    name: "final-beneficiary-procedure-legal-entities",
    props: {
        procedureId: {
            type: String,
            default: null,
        },
        legalEntities: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            societyType: societyType
        }
    },
    methods: {
        getSocietyType(value) {
            return this.societyType.find(x => x.value === value).text;
        }
    }
};
</script>