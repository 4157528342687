<template>
    <b-row>
        <h6 class="page-title mt-3">Rechazo</h6>
        <b-col>
            <ProcedureInfoProperty label="Fecha de rechazo" :text="date" />
            <ProcedureInfoProperty label="Motivo" :text="reject.reason" />
            <ProcedureInfoProperty label="Usuario" :text="user" />
        </b-col>
    </b-row>
</template>

<script>
import moment from "moment";
import ProcedureInfoProperty from "./ProcedureInfoProperty.vue";

export default {
    name: "procedure-reject",
    components: {
        ProcedureInfoProperty,
    },
    props: {
        reject: {
            type: Object,
            required: true,
        },
    },
    computed: {
        user() {
            return this.reject.cancellationSuperintendence.name;
        },
        date() {
            return this.parseAndConvertDate(this.reject.cancellationDate);
        },
    },
    methods: {
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
    },
};
</script>