<template>
    <b-modal v-model="isOpen" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
        header-bg-variant="light" centered no-close-on-backdrop hide-footer>
        <template #modal-header>
            <div class="modal-custom-title">
                <h5 class="modal-title">
                    {{ $t("approve-modal.title") }}
                </h5>
                <b-button size="sm" class="close-modal-button" variant="light" @click="close">
                    <b-icon-x scale="1.5" />
                </b-button>
            </div>
        </template>
        <div class="form-title mt-2 text-center">
            <h6>{{ $t("approve-modal.body") }}</h6>
            <b-overlay :show="approving" opacity="0.7" spinner-small spinner-variant="primary" class="d-inline-block">
                <b-button class="main-btn" @click="approve">
                    {{ $t("approve-modal.approve") }}
                </b-button>
            </b-overlay>
        </div>
    </b-modal>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

export default {
    name: "approve-modal",
    props: {
        id: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            approving: false,
        };
    },
    mounted() {
        this.isOpen = this.show;
    },
    watch: {
        show(show) {
            this.isOpen = show;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async approve() {
            this.approving = true;
            try {
                await RepositoryFactory.procedure.approveFinalBeneficiaryProcedure(
                    {
                        id: this.id,
                    }
                );
                this.close();
                this.showSuccess();
            } catch {
                this.close();
                this.showError();
            }
            this.approving = false;
        },
        showSuccess() {
            this.setModalInfo({
                title: this.$t(`approve-modal.success.title`),
                message: this.$t(`approve-modal.success.message`),
                subMessage: this.$t(`approve-modal.success.subMessage`),
                routeName: "procedures",
            });
            this.openModal();
        },
        showError() {
            this.setModalInfo({
                title: this.$t(`approve-modal.error.title`),
                message: this.$t(`approve-modal.error.message`),
                subMessage: this.$t(`approve-modal.error.subMessage`),
            });
            this.openModal();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.modal-custom-title {
    position: relative;
    width: 100%;
}

.close-modal-button {
    position: absolute;
    top: 0px;
    right: 0px;
}
</style>