<template>
    <b-table
        small
        stacked="lg"
        :items="items"
        :fields="columns"
        show-empty
        empty-text="No hay beneficiarios finales que mostrar"
    >
        <template #cell(constitutionDate)="data">
            {{ parseDate(data.item.constitutionDate) }}
        </template>
        <template #cell(acquisitionDate)="data">
            {{ parseDate(data.item.acquisitionDate) }}
        </template>
        <template #cell(reason)="data">
            <b-icon-card-list
                style="cursor: pointer"
                v-b-tooltip.hover.left
                variant="primary"
                :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
            />
        </template>
        <template #cell(creationDate)="data">
            {{ parseAndConvertDate(data.item.creationDate) }}
        </template>
    </b-table>
</template>

<script>
import moment from "moment";
import { reasons } from "@/config/final-beneficiary-reasons";

export default {
    name: "simple-final-beneficiaries",
    props: {
        finalBeneficiaries: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            columns: [],
            items: [],
        };
    },
    mounted() {
        this.items = this.finalBeneficiaries;
        if (this.items.length > 0) {
            this.setWho(this.items[0].finalBeneficiaryWho);
        }
    },
    methods: {
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        parseDate(date) {
            if (date === null) {
                return "-";
            }
            return moment(date).format("DD/MM/YYYY");
        },
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        },
        setWho(finalBeneficiaryWho) {
            if (finalBeneficiaryWho === 1) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "stockExchangeCompleteName", label: "Bolsa" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "jurisdictionStockExchangeWhereListed", label: "Jurisdicción" },
                    { key: "legalRepresentantCompleteName", label: "Representante" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "creationDate", label: "Fecha de creación" },
                ];
            }
            if (finalBeneficiaryWho === 2) {
                this.columns = [
                    { key: "country", label: "País" },
                    { key: "constitutionDate", label: "Fecha de constitución" },
                    { key: "acquisitionDate", label: "Fecha de adquisición" },
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "creationDate", label: "Fecha de creación" },
                ];
            }
            if (finalBeneficiaryWho === 3) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "legalRepresentantCompleteName", label: "Representante legal" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "creationDate", label: "Fecha de creación" },
                ];
            }
        },
    },
};
</script>