<template>
    <div class="mt-5 px-xl-5">
        <b-row align-h="between">
            <b-col sm="8">
                <h6 class="page-title pb-2">
                    Solicitud de información de sociedad y beneficiarios finales
                </h6>
            </b-col>
            <b-col sm="4">
                <ProcedureTimer
                    v-if="allowViewSocieties"
                    :procedure="procedure"
                />
            </b-col>
        </b-row>

        <b-card class="mb-4" no-body>
            <b-row v-if="isLoading" class="text-center">
                <b-col>
                    <h6 class="page-title mt-3">
                        Cargando información del proceso ...
                    </h6>
                    <b-spinner class="ms-2" variant="primary" />
                </b-col>
            </b-row>
            <b-tabs v-if="!isLoading" card>
                <b-tab title="Información" active>
                    <b-card-text>
                        <b-row>
                            <b-col md="6">
                                <ProcedureInfo :procedure="procedure" />
                            </b-col>
                            <b-col md="6">
                                <ProcedureAttachments
                                    :attachments="procedure.attachments"
                                />
                            </b-col>
                        </b-row>
                        <b-row v-if="allowApproveOrReject" class="mt-4 mb-2">
                            <b-col class="text-end">
                                <b-button
                                    class="main-danger-btn"
                                    variant="danger"
                                    @click="openReject"
                                >
                                    Rechazar
                                </b-button>
                                <b-button
                                    class="main-btn ms-2"
                                    @click="openApprove"
                                >
                                    Aprobar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
                <b-tab title="Histórico">
                    <b-card-text>
                        <b-row>
                            <b-col>
                                <ProcedureTimeline :procedure="procedure" />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
                <b-tab v-if="allowViewSocieties" title="Estado Actual de la Sociedad">
                    <LegalEntities
                        :procedureId="id"
                        :legalEntities="procedure.responses"
                    />
                </b-tab>
                <b-tab v-if="allowViewSocieties" title="Historial de las Sociedades">
                    <LegalEntities
                        :procedureId="id"
                        :legalEntities="procedure.historyResponses"
                        v-if="procedure.historyResponses.length > 0"
                    />
                    <div class="text-center mt-3" v-else>
                        <h6>Actualmente, no se encuentran registros anteriores en el sistema.</h6>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
        <ApproveModal
            v-if="allowApproveOrReject"
            :id="id"
            :show="showApprove"
            @close="closeApprove"
        />
        <RejectModal
            v-if="allowApproveOrReject"
            :id="id"
            :show="showReject"
            @close="closeReject"
        />
    </div>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/services/api";

import ProcedureInfo from "./procedure-info/ProcedureInfo.vue";
import ProcedureAttachments from "./procedure-attachments/ProcedureAttachments.vue";
import ProcedureTimeline from "./procedure-timeline/ProcedureTimeline.vue";
import ApproveModal from "./modals/ApproveModal.vue";
import RejectModal from "./modals/RejectModal.vue";
import LegalEntities from "./legal-entities/LegalEntities.vue";
import ProcedureTimer from "./ProcedureTimer.vue";

export default {
    name: "procedure",
    components: {
        ProcedureInfo,
        ProcedureAttachments,
        ProcedureTimeline,
        ApproveModal,
        RejectModal,
        LegalEntities,
        ProcedureTimer
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            procedure: null,

            showApprove: false,
            showReject: false,
        };
    },
    mounted() {
        this.retrieveProcedure();
    },
    computed: {
        ...mapGetters("application", ["isSuperintendence", "isOfficial"]),
        allowApproveOrReject() {
            if (this.procedure === null) return false;

            let status = this.procedure.status;
            return this.isSuperintendence && (status === 0 || status === 1);
        },
        allowViewSocieties() {
            let status = this.procedure?.status;

            return this.isOfficial && status === 3;
        },
    },
    methods: {
        async retrieveProcedure() {
            this.isLoading = true;
            try {
                let response =
                    await RepositoryFactory.procedure.getFinalBeneficiaryProcedure(
                        this.id
                    );
                let procedure = response.data;

                procedure.startDate = this.parseAndConvertDate(
                    procedure.timeline.startDate
                );

                this.procedure = procedure;
            } catch (error) {
                window.console.log(error);
            }
            this.isLoading = false;
        },
        openApprove() {
            this.showApprove = true;
        },
        closeApprove() {
            this.showApprove = false;
        },
        openReject() {
            this.showReject = true;
        },
        closeReject() {
            this.showReject = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
    },
};
</script>