<template>
    <div>
        <div v-if="isLoading">
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
            </div>
            <div class="text-center text-primary my-2">
                <strong>Cargando datos de la entidad jurídica ...</strong>
            </div>
        </div>
        <div v-else>
            <h6 class="page-title text-uppercase mb-3">Datos de la sociedad</h6>
            <b-row>
                <component
                    :is="legalEntityComponent"
                    :legalEntity="legalEntity"
                />
                <b-col v-if="legalEntity != null && legalEntity.jurisdictions.length > 0">
                    <JurisdictionsList :legalEntity="legalEntity" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

import NotCarryOutOperationsInPanamaLegalPerson from "../../legal-entity-details/legal-entity-type/NotCarryOutOperationsInPanamaLegalPerson.vue";
import CarryOutOperationsInPanamaLegalPerson from "../../legal-entity-details/legal-entity-type/CarryOutOperationsInPanamaLegalPerson.vue";
import ListedOnStockExchangeLegalPerson from "../../legal-entity-details/legal-entity-type/ListedOnStockExchangeLegalPerson.vue";
import StateOrMultilateralEntity from "../../legal-entity-details/legal-entity-type/StateOrMultilateralEntity.vue";
import OwnedStateByLegalPerson from "../../legal-entity-details/legal-entity-type/OwnedStateByLegalPerson.vue";
import MixedOperationsLegalEntity from "../../legal-entity-details/legal-entity-type/MixedOperationsLegalEntity.vue";
import JurisdictionsList from "../../legal-entity-details/JurisdictionsList.vue";

export default {
    name: "legal-entity",
    components: {        
        JurisdictionsList,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        procedureId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            legalEntity: null,
        };
    },
    computed: {
        legalEntityComponent() {
            if (this.legalEntity === null) {
                return null;
            }
            switch (this.legalEntity.legalEntityType) {
                case 0:
                    return NotCarryOutOperationsInPanamaLegalPerson;
                case 1:
                    return CarryOutOperationsInPanamaLegalPerson;
                case 2:
                    return ListedOnStockExchangeLegalPerson;
                case 3:
                    return StateOrMultilateralEntity;
                case 4:
                    return OwnedStateByLegalPerson;
                case 5:
                    return MixedOperationsLegalEntity;
            }
            return null;
        },
    },
    mounted() {
        this.retrieveLegalEntity();
    },
    methods: {
        async retrieveLegalEntity() {
            this.loading = true;
            try {
                let response = await RepositoryFactory.legalEntity.get({
                    id: this.id,
                    procedureId: this.procedureId,
                });
                let legalEntity = response.data;
                this.legalEntity = legalEntity;
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
    },
};
</script>