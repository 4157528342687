<template>
    <div>
        <h6 class="page-title text-uppercase mt-3 mb-3">
            Datos de los beneficiarios
        </h6>

        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
            <b-tabs class="mt-3">
                <b-tab title="Personas Naturales" active>
                    <CompleteFinalBeneficiaries
                        :finalBeneficiaries="finalBeneficiaries.filter(n => n.finalBeneficiaryWho === 0)"
                    />
                </b-tab>
                <b-tab title="Persona Jurídica que cotiza en bolsa">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="finalBeneficiaries.filter(n => n.finalBeneficiaryWho === 1)"
                    />
                </b-tab>
                <b-tab title="Estado">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="finalBeneficiaries.filter(n => n.finalBeneficiaryWho === 2)"
                    />
                </b-tab>
                <b-tab title="Entidad Estatal o Multilateral">
                    <SimpleFinalBeneficiaries
                        :finalBeneficiaries="finalBeneficiaries.filter(n => n.finalBeneficiaryWho === 3)"
                    />
                </b-tab>
            </b-tabs>
        </b-card>

    </div>
</template>

<script>
import SimpleFinalBeneficiaries from "./SimpleFinalBeneficiaries.vue";
import CompleteFinalBeneficiaries from "./CompleteFinalBeneficiaries.vue";

export default {
    name: "final-beneficiaries",
    components: {
        SimpleFinalBeneficiaries,
        CompleteFinalBeneficiaries,
    },
    props: {
        finalBeneficiaries: {
            type: Array,
            required: true,
        },
    },
};
</script>